/* Desktops and laptops ----------- */
@media (hover: hover) and (pointer: fine) {
    /* Styles */

    *
    {
        margin: 0;
        padding: 0;
    }
    
    html,
    body
    {
        overflow: hidden;
    }
    
    .webgl 
    {
        position: fixed;
        top: 0;
        left: 0;
        outline: none;
    }
    
    .loading-bar
    {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 1px;
        background: #838383;
        transform: scaleX(0);
        transform-origin: top left;
        transition: transform 0.5s;
        will-change: transform;
        z-index: 1;
    }
    
    
    .loading-bar.ended
    {
        transform-origin: 100% 0;
        transition: transform 1.5s ease-in-out;
    }
    .logo {
        fill: rgb(128, 128, 128,0.2);
        margin: auto;
        position: absolute;
        transition: fill 0.4s ease-in-out;
        right: -100px;
        bottom: 10px;
        }
    .logo:hover {
        fill: rgba(29, 29, 29, 0.2);
        transition: fill 0.4s ease-in-out;
    }
    
    .wasd {
    
        margin: auto;
        position: absolute;
        
        left: 10px;
        bottom: 10px;
        
      }
      .black
    {
        position: absolute;
            width: 100%;
        height: 100%;
        background: #292929;
        pointer-events: none;
        transition: opacity 2s;
    }
    .black.blackEnded
    {
        opacity: 0.0;
        
    }
    
    .mouse {
        fill: #000000;
        margin: auto;
        position: absolute;
        
        left: 92px;
        bottom: 10px;
        
      }
      .click {
        fill: #000000;
        margin: auto;
        position: absolute;
        
        left: 125px;
        bottom: 6px;
        
      }
    
      .click.ended
    {
        
        fill: rgb(128, 128, 128,0.0);
        transition: fill 1.5s ease-in-out;
    }

    .click.started
    {
        fill: #000000;
        transition: fill 1.5s ease-in-out;
     
    }

    .release {
        fill: rgb(128, 128, 128,0.0);
        margin: auto;
        position: absolute;
        
        left: 125px;
        bottom: 10px;
        
      }
    
      .release.started
    {
        fill: #000000;
        transition: fill 1.5s ease-in-out;
     
    }

    .release.ended
    {
        
        fill: rgb(128, 128, 128,0.0);
        transition: fill 1.5s ease-in-out;
    }

    #tempTextJoy
    {
      visibility: hidden;
      display: none;
      
    }
   
    
  }


  /* Smartphones (portrait and landscape) ----------- */
  @media (hover: none) and (pointer: coarse) {
    /* Styles */


    *
    {
        margin: 0;
        padding: 0;
      
    }
    
    html,
    body
    {
        overflow: hidden;
        
    }
    
    .webgl
    {
        position: fixed;
        top: 0;
        left: 0;
        outline: none;
    }
    
    .loading-bar
    {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 1px;
        background: #838383;
        transform: scaleX(0);
        transform-origin: top left;
        transition: transform 0.5s;
        will-change: transform;
        z-index: 1;
    }
    
    
    .loading-bar.ended
    {
        transform-origin: 100% 0;
        transition: transform 1.5s ease-in-out;
    }
    .logo {
        fill: rgb(128, 128, 128,0.2);
        margin: auto;
        position: absolute;
        right: -145px;
        bottom: 1px;
        transform: scale(0.7,0.7);
        }
    
        .mouse {
            display: none;
            
          }
          .click {
            display: none;
            
          }

          .wasd {
    
            display: none;
            
          }

          .release {
    
            display: none;
          
            
          }

          #joystickWrapperLeft {
            margin: auto;
        position: absolute;
            bottom: 20px;
            left: 10px;
            touch-action: manipulation;
            pointer-events: auto;
            display: block;
            position: absolute;
            background-color: transparent;
            width: 120px;
            height: 120px;
            z-index: 12;
            touch-action: manipulation;
            background-color: rgba(148, 148, 148, 0.0);
          }
          
          
          #joystickWrapperRight {
            margin: auto;
        position: absolute;
            bottom: 20px;
            right: 10px;
            touch-action: manipulation;
            pointer-events: auto;
            display: block;
            position: absolute;
            background-color: transparent;
            width: 120px;
            height: 120px;
            z-index: 12;
            touch-action: manipulation;
            background-color: rgba(148, 148, 148, 0.0);
          } 
  
  }
  

  #myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  }
  
  .content {
    position: fixed;
    right: 2;
    bottom: 0;
    color: #7e7e7e;
    width: 100%;
    
  }
  